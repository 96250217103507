<template>
  <div>
    <v-container class="px-5 mb-10">
      <v-row>
        <v-col cols="12">
          <div class="primary--text text-h4">
            Donation
          </div>
          <div class="text-body-1 mt-2">
            Create donation campaign and share with people.
          </div>
        </v-col>
      </v-row>
      <v-row class="mt-8">
          <v-col cols="12" md="4">
            <v-card class="pa-10 rounded-lg text-center" elevation="2" @click="dialog = true">
               <v-icon color="primary" size="32">mdi-plus</v-icon>
               <div class="primary--text">Create Givaway or Donation</div>
            </v-card>
          </v-col>
        </v-row>
      <!--v-container fluid style="background: #e3f2fd" class="grey lighten-5 pa-3 pa-md-5 my-10 rounded-lg">
         <v-row>
            <v-col cols="12" md="4">
               <v-card class="py-3 px-5 rounded-lg" elevation="2">
                  <v-row>
                     <v-col cols="8">
                        <div class="text-caption text--secondary">
                           03/07/2021
                        </div>
                        <div class="text-h5 mt-2 font-weight-bold primary--text">
                        July Giveaway
                        </div>
                        <p class="mt-3 text-body-2 mb-0">
                        I am giving away &#8358;2000 for free to 200 persons.
                        </p>
                        <div><v-btn class="secondary mt-2" small>Copy</v-btn></div>
                     </v-col>
                     <v-col cols="4" class="text-center">
                        <v-img src="@/assets/icons/donation.png"></v-img>
                     </v-col>
                  </v-row>
               </v-card>
            </v-col>
         </v-row>
      </v-container-->
      
      <v-dialog v-model="dialog" width="500">
         <v-card class="form-colored-bg business-card pa-6">
            <v-form lazy-validation ref="form" v-model="valid">
               <div class="text-h5 primary--text">Create Donation/ Giveaway</div>
               <v-divider class="my-5"></v-divider>
               <p class="label mb-2">Select type</p>
                  <v-select outlined v-model="form.type" :items="['Donation', 'Givaway']" required :rules="[v=>!!v || 'This field is required']"></v-select>
         <p class="label mb-2">Name of {{ form.type}}</p>
         <v-text-field outlined v-model="form.name" required :rules="[v=>!!v || 'This field is required']"></v-text-field>
         <p class="label mb-2">Description</p>
         <v-text-field outlined v-model="form.description" required :rules="[v=>!!v || 'This field is required']"></v-text-field>
         <v-row>
            <v-col cols="12" md="6">
               <p class="label mb-2">Start date</p>
               <v-menu
                  v-model="start"
                  :close-on-content-click="false"
                  max-width="290"
               >
                  <template v-slot:activator="{ on }">
                     <v-text-field
                     append-icon="event_note"
                     outlined
                     :value="form.start_date"
                     placeholder="Select start date"
                     readonly solo
                     v-on="on"
                     ></v-text-field>
                  </template>
                  <v-date-picker
                     light
                     v-model="form.start_date"
                     :min="nowDate()"
                     @change="start = false"
                  ></v-date-picker>
               </v-menu>
            </v-col>
            <v-col cols="12" md="6">
               <p class="label mb-2">End date</p>
               <v-menu
                  v-model="end"
                  :close-on-content-click="false"
                  max-width="290"
               >
                  <template v-slot:activator="{ on }">
                     <v-text-field
                     append-icon="event_note"
                     outlined
                     :value="form.end_date"
                     placeholder="Select end date"
                     readonly solo
                     v-on="on"
                     ></v-text-field>
                  </template>
                  <v-date-picker
                     light
                     v-model="form.end_date"
                     :min="nowDate()"
                     @change="end = false"
                  ></v-date-picker>
               </v-menu>
            </v-col>
         </v-row>
         <v-btn class="primary" :loading="loading" block large @click="$refs.form.validate() ? creategiveaway() : null">Create</v-btn>
         <v-btn class="mt-2" depressed block large @click="dialog = !dialog">Close</v-btn>
         </v-form>
         </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  components: {
  },
  layout: "dashboard",
  transition: "default",
  data() {
    return {
      dialog: false,
      loader: false,
      start: false,
      end: false,
      form: {
         type: 'Donation'
      },
      valid: true,
      loading: false,
      item: {},
    };
  },
  mounted() {
  },
  methods: {
    async creategiveaway() {
      const data = {
        user_id: this.user.id,
      };
      try {
       
      } catch(err) {
       // this.$toast.error(err.response.data.message);
      }
    },

    nowDate() {
      return new Date().toISOString().slice(0,10)
    },
  },

  computed: {
    ...mapGetters("auth", ["user", "loan_balance"]),
  }
}

</script>
<style lang="scss">
.v-application .elevation-5 {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25) !important;
}
.v-application .elevation-2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.1),
    0px 2px 2px 0px rgba(0, 0, 0, 0.07), 0px 1px 5px 0px rgba(0, 0, 0, 0.06) !important;
}
.v-application .elevation-0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 0px rgba(0, 0, 0, 0),
    0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
}
.v-menu__content {
  box-shadow: 0 0.04rem 0.4rem rgba(0, 0, 0, 0.15) !important;
  border: 1px solid #dddddd;
}
.t-color tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
a {
  text-decoration: none;
}
</style>
